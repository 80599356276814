import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const HeroContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;
    background-color: #f2f2f2;
    padding: 20px;
`;

const Title = styled.h1`
    font-size: 2em;
    margin: 20px 0;
`;

const Description = styled.p`
    font-size: 1.2em;
    text-align: center;
    max-width: 600px;
`;



const LearnMoreButton = styled.button`
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`;

const Hero = () => {
    const navigate = useNavigate();
    return (
        <HeroContainer>
            <Title>Welcome to Our Website</Title>
            <Description>We provide the best solutions for your needs.</Description>
            <LearnMoreButton onClick={() => navigate('/login')}>Login</LearnMoreButton>
        </HeroContainer>
    );
};

export default Hero;
