// redux/actions/authActions.js

import {
    PENDING_ORG,
    GET_ORG,
    GET_ORG_LIST,
    CREATE_ORG,
    FETCH_ORG,
    UPDATE_ORG,
    DELETE_ORG,
  } from '../actionTypes.js';

export const orgPending = () => ({
    type: PENDING_ORG,
  });

export const orgPaginatedList = (payload) => ({
  type: GET_ORG,
  payload
});

export const orgList = (payload) => ({
  type: GET_ORG_LIST,
  payload
});


export const orgCreate = (payload) => ({
  type: CREATE_ORG,
  payload
});

export const orgFetch = (payload) => ({
  type: FETCH_ORG,
  payload
});

export const orgUpdate = (payload) => ({
  type: UPDATE_ORG,
  payload
});

export const orgDelete = (payload) => ({
  type: DELETE_ORG,
  payload
});