// redux/reducers/authReducer.js

import {
  PENDING_APP,
  PAGE_NUM_APP,
  GET_APP,
  CREATE_APP,
  FETCH_APP,
  UPDATE_APP,
  DELETE_APP,
  CLEAR_APP,
  CLEAR_ALL,
} from "../actionTypes.js";

const initialState = {
  isLoading: false,
  app: [],
  pageNumber: 1,
  create: null,
  delete: null,
  fetch: null,
  update: null,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case PENDING_APP:
      return {
        ...state,
        isLoading: true,
        create: null,
        delete: null,
        fetch: null,
        update: null,
      };
    case PAGE_NUM_APP:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case GET_APP:
      return {
        ...state,
        app: action.payload,
        isLoading: false,
        created: false,
      };
    case CREATE_APP:
      return {
        ...state,
        isLoading: false,
        created: action.payload,
      };
    case FETCH_APP:
      return {
        ...state,
        isLoading: false,
        fetch: action.payload,
      };
    case UPDATE_APP:
      return {
        ...state,
        isLoading: false,
        update: action.payload,
      };
    case DELETE_APP:
      return {
        ...state,
        isLoading: false,
        created: false,
        deleted: action.payload,
      };
    case CLEAR_ALL:
    case CLEAR_APP:
        return {
          ...state,
          ...initialState,
        }
    default:
      return state;
  }
};

export default appReducer;
