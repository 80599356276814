import React, {useState, useEffect} from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";

import { useNavigate } from "react-router-dom";

import Login from "./components/account/signin";
import AuthenticatedLayout from "./components/layout/auth";
// home
import HomeLayout from "./components/layout/home";
import HomePage from "./components/home/index";
// authenticated
import Dashboard from "./components/dashboard/index";
import Org from "./components/org/index";
import AppType from "./components/appType/index";
import App from "./components/app/index";
import AppData from "./components/appData/index";


const AppRoutes = () => {
const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const auth = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    const validUpto = auth?.userData?.expiry;
    if (validUpto) {
      const expiryDate = new Date(validUpto * 1000);
      if (new Date() < expiryDate) {
        setIsAuthenticated(true);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, [auth, navigate]);

  return isAuthenticated ? (
    <AuthenticatedLayout>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/org/*" element={<Org />} />
        <Route path="/app-type/*" element={<AppType />} />
        <Route path="/app/*" element={<App />} />
        <Route path="/app-data/*" element={<AppData />} />
        {/* Add more authenticated routes here */}
      </Routes>
    </AuthenticatedLayout>
  ) : (
    <HomeLayout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        {/* Add more non-authenticated routes here */}
      </Routes>
    </HomeLayout>
  );
};

export default AppRoutes;
