// components/account/Login.js

import React, { useState, useEffect } from 'react';
import { Container, LoginForm, Input, Button, FormHeader } from './styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { LOGIN_REQUEST } from '../../redux/actionTypes';  // Update this path to point to your actual action type definitions


const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();  // Get the navigate function
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginStatus, setLoginStatus] = useState('');

    const auth = useSelector(state => state.auth, shallowEqual);

    useEffect(() => {
        const validUpto = auth?.userData?.expiry;
        
        if (validUpto) {
          const expiryDate = new Date(validUpto * 1000);
          if (new Date() < expiryDate) {
            console.log(validUpto)
            navigate("/dashboard");
          } 
        } else {
          navigate("/login");
        }
      }, [auth.userData, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            dispatch({
                type: LOGIN_REQUEST,
                email: email,
                password: password
              });
         
            //   navigate('/dashboard');
        } catch (error) {
            setLoginStatus("Login failed.");
        }
    };

    return (
        <Container>
            
            <LoginForm onSubmit={handleSubmit}>
            <FormHeader>Login</FormHeader>
                <Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button type="submit">Login now</Button>
            </LoginForm>
        </Container>
    );
};

export default Login;
