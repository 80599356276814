import React from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledReactPaginate = styled(ReactPaginate)`
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;

  li {
    display: inline-block;
    margin-right: 5px;

    a {
      display: block;
      padding: 10px;
      text-decoration: none;
      color: #333;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: #eee;
      }

      &.selected {
        background-color: #007bff;
        color: #fff;
        border: 1px solid #007bff;
      }
    }
  }
`;

const CustomPagination = ({ pageCount, onPageChange, initialPage }) => {
  return (
    <PaginationContainer>
      <StyledReactPaginate
        pageCount={pageCount || 0}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        onPageChange={onPageChange}
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        activeClassName="selected"
        initialPage={initialPage || 0}
      />
    </PaginationContainer>
  );
};

export default CustomPagination;
