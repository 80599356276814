import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { format } from "date-fns";

const RowCard = ({ rows }) => {
  const navigate = useNavigate();

  return (
    <>
      <Table>
        <thead>
          <TableRow>
            <TableHeader width={1}>Id</TableHeader>
            <TableHeader width={2}>Organization</TableHeader>

            <TableHeader width={2}>Created</TableHeader>
            <TableHeader width={2}>Updated</TableHeader>
            <TableHeader width={1}></TableHeader>
          </TableRow>
        </thead>
        <tbody>
          {rows &&
            rows.map((row, i) => (
              <TableRow key={i}>
                <TableCell>{row?.id}</TableCell>
                <TableCell>{row?.name}</TableCell>

                <TableCell>
                  {row?.created_at &&
                    format(new Date(row?.created_at), "dd-MMM-yyyy hh:mm")}
                </TableCell>
                <TableCell>
                  {row?.created_at &&
                    format(new Date(row?.updated_at), "dd-MMM-yyyy hh:mm")}
                </TableCell>
                <TableCell>
                  <Icon
                    onClick={() =>
                      navigate(`/org/details/${row?.id}`)
                    }
                  >
                    ›
                  </Icon>
                </TableCell>
              </TableRow>
            ))}
        </tbody>
      </Table>
    </>
  );
};


export default RowCard;



const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  width: ${(props) => props.width || "auto"};
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  width: ${(props) => props.width || "auto"};
  padding: 10px;
  border: 1px solid #ddd;
  background-color: ${(props) => (props.noResponse ? "#A8D5B7" : "#F0A8A8")};
`;

const Icon = styled.i`
  cursor: pointer;
`;
