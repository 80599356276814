import React from 'react';
import styled from 'styled-components';


const SearchBar = ({ searchTerm, setSearchTerm }) => {
  return (
    <FormContainer>
      <label>Search</label>
      <Input
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Search..."
      />
    </FormContainer>
  );
};


export default SearchBar;

const FormContainer = styled.form`
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 8px;
  font-size: 16px;
`;
