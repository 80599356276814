// services/api.js

import axios from "axios";

const LOCALHOST = "http://127.0.0.1:8080"
const REMOTE_SERVER = "https://datastruk.com";

// const SERVER_ENDPOINT = LOCALHOST
const SERVER_ENDPOINT = REMOTE_SERVER;

const token = localStorage.getItem("token");

// /api/account/signin
export const loginAPI = (email, password) => {
  return axios
    .post(`${SERVER_ENDPOINT}/api/account/login`, { email, password })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message || "Login failed!");
    });
};

// Function to get the authentication token from local storage
const getToken = () => {
  return localStorage.getItem("token");
};

// Common headers with Authorization token
const headers = {
  Authorization: `${getToken()}`,
  "Content-Type": "application/json", // Add other headers if needed
};


// /api/org
export const apiOrgGet = () => {
  return axios
    .get(`${SERVER_ENDPOINT}/api/org`, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        error.response.data.message || "Unable to get list of org"
      );
    });
};

export const apiOrgPaginatedGet = () => {
  return axios
    .get(`${SERVER_ENDPOINT}/api/org/paginated`, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        error.response.data.message || "Unable to get list of paginated org"
      );
    });
};
// /api/org/create
export const apiOrgCreate = (payload) => {
  return axios
    .post(`${SERVER_ENDPOINT}/api/org/create`, payload, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message || "Unable to create org");
    });
};

// /api/org/fetch details/{id}
export const apiOrgFetch = (payload) => {
  return axios
    .get(`${SERVER_ENDPOINT}/api/org/details/${payload.id}`, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message || "Unable to get details of org");
    });
};

// /api/org/update /update/{id}
export const apiOrgUpdate = (payload) => {
  return axios
    .patch(`${SERVER_ENDPOINT}/api/org/update/${payload.id}`, payload.data, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.response?.data?.message || "Unable to update org");
    });
};


// /api/app-type
// export const apiAppTypePaginatedGet = () => {
//   return axios
//     .get(`${SERVER_ENDPOINT}/api/app-type/paginated`, { headers })
//     .then((response) => response.data)
//     .catch((error) => {
//       throw new Error(
//         error.response.data.message || "Unable to get list of paginated app-type"
//       );
//     });
// };

export const apiAppTypeGet = () => {
  return axios
    .get(`${SERVER_ENDPOINT}/api/app-type`, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        error.response.data.message || "Unable to get list of app-type"
      );
    });
};

// /api/app-type/create
export const apiAppTypeCreate = (payload) => {
  return axios
    .post(`${SERVER_ENDPOINT}/api/app-type/create`, payload, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message || "Unable to create app-type");
    });
};

// /api/app-type/fetch details/{id}
export const apiAppTypeFetch = (payload) => {
  return axios
    .get(`${SERVER_ENDPOINT}/api/app-type/details/${payload.id}`, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message || "Unable to get details of app-type");
    });
};

// /api/app-type/update /update/{id}
export const apiAppTypeUpdate = (payload) => {
  return axios
    .patch(`${SERVER_ENDPOINT}/api/app-type/update/${payload.id}`, payload.data, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.response?.data?.message || "Unable to update app-type");
    });
};


// /api/app
export const apiAppGet = () => {
  return axios
    .get(`${SERVER_ENDPOINT}/api/app-type`, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        error.response.data.message || "Unable to get list of app"
      );
    });
};

export const apiAppPaginatedGet = () => {
  return axios
    .get(`${SERVER_ENDPOINT}/api/app/paginated`, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        error.response.data.message || "Unable to get list of paginated app"
      );
    });
};

// /api/app/create
export const apiAppCreate = (payload) => {
  return axios
    .post(`${SERVER_ENDPOINT}/api/app/create`, payload, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message || "Unable to create app");
    });
};

// /api/app-type/fetch details/{id}
export const apiAppFetch = (payload) => {
  return axios
    .get(`${SERVER_ENDPOINT}/api/app/details/${payload.id}`, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message || "Unable to get details of app");
    });
};

// /api/app-type/update /update/{id}
export const apiAppUpdate = (payload) => {
  return axios
    .patch(`${SERVER_ENDPOINT}/api/app/update/${payload.id}`, payload.data, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.response?.data?.message || "Unable to update app");
    });
};

// /api/app-data
// export const apiAppDataPaginatedGet = () => {
//   return axios
//     .get(`${SERVER_ENDPOINT}/api/app-data/paginated`, { headers })
//     .then((response) => response.data)
//     .catch((error) => {
//       throw new Error(
//         error.response.data.message || "Unable to get list of paginated app-data"
//       );
//     });
// };

export const apiAppDataGet = (payload) => {
  const url = `${SERVER_ENDPOINT}/api/app-data${payload.application_id? `?application_id=${payload.application_id}`: ''}`
  return axios
    .get(url, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        error.response.data.message || "Unable to get list of app-data"
      );
    });
};

export const apiAppDataGetList = (payload) => {
  return axios
    .get(`${SERVER_ENDPOINT}/api/app-data/${payload.application_id}/list`, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(
        error.response.data.message || "Unable to get list of app-data"
      );
    });
};

// /api/app-data/create
export const apiAppDataCreate = (payload) => {
    console.log(payload)
    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("application_id", payload.application_id);
    formData.append("csv_file", payload.csv_file);

    const url = `${SERVER_ENDPOINT}/api/app-data/create-file`
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: url,
      headers: { 
        Authorization: `${getToken()}`
      },
      data : formData
    };
   return axios.request(config)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message || "Unable to upload app-data file");
    });

  // return axios
  //   .post(url, {data: formData}, { Authorization: getToken() })
  //   .then((response) => response.data)
  //   .catch((error) => {
  //     throw new Error(error.response.data.message || "Unable to create app-data");
  //   });
};


// /api/app-data/fetch details/{id}
export const apiAppDataFetch = (payload) => {
  return axios
    .get(`${SERVER_ENDPOINT}/api/app-data/details/${payload.id}`, { headers })
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.data.message || "Unable to get details of app-data");
    });
};

// /api/app-data/update /update/{id}
export const apiAppDataUpdate = (payload) => {
  return axios
    .patch(`${SERVER_ENDPOINT}/api/app-data/update/${payload.id}`, payload.data, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.response?.data?.message || "Unable to update app-data");
    });
};

// /api/app-data/delete /update/{id}
export const apiAppDataDelete = (payload) => {
  return axios
    .delete(`${SERVER_ENDPOINT}/api/app-data/delete/${payload.id}`, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error.response?.data?.message || "Unable to update app-data");
    });
};