// components/OrgForm.js

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";

import styled from "styled-components";
import Select from "react-select";

import {
  SG_CREATE_APP,
  SG_FETCH_APP,
  SG_UPDATE_APP,
  SG_GET_APP_TYPE,
  SG_GET_ORG_LIST,
} from "../../redux/actionTypes";

const FormData = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [name, setName] = useState("");
  const [detail, setDetail] = useState(false);
  const [selectOrgList, setSelectOrgList] = useState([]);
  const [selectAppTypeList, setSelectAppTypeList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState({});
  const [selectedAppType, setSelectedAppType] = useState({});

  const { get_app, get_org, get_app_type } = useSelector(
    (state) => ({
      get_app: state.app,
      get_org: state.org,
      get_app_type: state.app_type,
    }),
    shallowEqual
  );

  useEffect(() => {
    setSelectOrgList(get_org?.list);
  }, [get_org]);

  useEffect(() => {
    setSelectAppTypeList(get_app_type?.app_type);
  }, [get_org]);

  useEffect(() => {
    dispatch({
      type: SG_GET_APP_TYPE,
    });
    dispatch({
      type: SG_GET_ORG_LIST,
    });
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch({
        type: SG_FETCH_APP,
        payload: { id },
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    setDetail(false);
    if (id) {
      setDetail(get_app?.fetch);
    }
  }, [get_app.fetch, id]);

  useEffect(() => {
    setName("");
    if (detail?.name) {
      setName(detail?.name);
    }
  }, [detail]);

  useEffect(() => {
    setSelectedOrg({});
    if (detail?.organization_id) {
      setSelectedOrg(
        selectOrgList.find((so) => so.id === detail?.organization_id)
      );
    }
  }, [selectOrgList, detail]);

  useEffect(() => {
    setSelectedAppType({});
    if (detail?.application_type_id) {
      setSelectedAppType(
        selectAppTypeList.find((so) => so.id === detail?.application_type_id)
      );
    }
  }, [selectAppTypeList, detail]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const orgData = {
      name,
      organization_id: selectedOrg?.id,
      application_type_id: selectedAppType.id,
    };

    if (id) {
      dispatch({
        type: SG_UPDATE_APP,
        payload: {
          id: id,
          data: orgData,
        },
      });
    } else {
      dispatch({ type: SG_CREATE_APP, payload: orgData });
    }

    // onClose();
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Name:</Label>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>Org:</Label>
          <Select
            value={selectedOrg}
            onChange={(value) => setSelectedOrg(value)}
            options={selectOrgList}
            getOptionValue={(selectOrgList) => selectOrgList.id}
            getOptionLabel={(selectOrgList) => selectOrgList.name}
          />
        </FormGroup>
        <FormGroup>
          <Label>Application Type:</Label>
          <Select
            value={selectedAppType}
            onChange={(value) => setSelectedAppType(value)}
            options={selectAppTypeList}
            getOptionValue={(selectAppTypeList) => selectAppTypeList.id}
            getOptionLabel={(selectAppTypeList) => selectAppTypeList.name}
          />
        </FormGroup>
        <Button type="submit">{id ? "Edit" : "Add"}</Button>
      </form>
    </FormContainer>
  );
};

export default FormData;

const FormContainer = styled.div`
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
`;

const FormGroup = styled.div`
  margin: 0 15px 10px 5px;
`;

const Label = styled.label`
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 16px;
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;
