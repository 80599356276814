// redux/actions/authActions.js

import {
    PENDING_APP,
    GET_APP,
    CREATE_APP,
    FETCH_APP,
    UPDATE_APP,
    DELETE_APP,
  } from '../actionTypes.js';

export const appPending = () => ({
    type: PENDING_APP,
  });

export const appList = (payload) => ({
  type: GET_APP,
  payload
});

export const appCreate = (payload) => ({
  type: CREATE_APP,
  payload
});

export const appFetch = (payload) => ({
  type: FETCH_APP,
  payload
});

export const appUpdate = (payload) => ({
  type: UPDATE_APP,
  payload
});

export const appDelete = (payload) => ({
  type: DELETE_APP,
  payload
});