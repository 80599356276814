import React, { useState, useEffect } from "react";
import styled from "styled-components";

import SearchBar from "./SearchBar";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_APP_DATA } from "../../redux/actionTypes";


const List = ({ applicationId, reload }) => {
  const dispatch = useDispatch();
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch({
      type: SG_GET_APP_DATA,
      payload: {application_id: applicationId},
    });

    setLoading(true);
  }, [dispatch, reload]);

  const { get_app_data } = useSelector(
    (state) => ({
      get_app_data: state.app_data,
    }),
    shallowEqual
  );

  useEffect(() => {
    setResults(get_app_data?.app_data);

  }, [get_app_data]);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLoading(get_app_data?.isLoading);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [get_app_data]);

  
  return (
    <Container>
      <LoaderContainer>
        {loading && (
          <CustomLoader>
            {/* Your loading spinner or component */}
            Loading...
          </CustomLoader>
        )}
        <RowCardContainer>
          {results && <RowCard rows={results} />}
        </RowCardContainer>
      </LoaderContainer>




    </Container>
  );
};

export default List;

const RowCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const Container = styled.div`
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const LoaderContainer = styled.div`
  position: relative;
  min-height: 300px;
`;

const CustomLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* You can customize the color of your loading text */
  z-index: 1000; /* Make sure it's above other elements */

  /* Additional styling for your loading spinner or component */
`;

