// components/layout/home.js
import {  AuthenticatedLayoutWrapper } from './styles.js'; // Assuming you saved the styled components in a separate file

import React from 'react';

const Home = ({ children }) => {
    return (
        <AuthenticatedLayoutWrapper>
          {children} 
        </AuthenticatedLayoutWrapper>
      );
}

export default Home;
