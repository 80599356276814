// redux/actions/authActions.js

import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE
  } from '../actionTypes.js';

export const loginRequest = (email, password) => ({
  type: LOGIN_REQUEST,
  email,
  password
});

export const loginSuccess = (userData) => ({
  type: LOGIN_SUCCESS,
  payload: userData
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  error
});
