import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SidebarWrapper, SidebarContainer, ToggleButton, SidebarMenu, SidebarLink } from './styles';

const Sidebar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);  // State to handle if sidebar is open or closed

  function LinkClicked(endPoint) {

    navigate(endPoint)
    setIsOpen(false)
  }

  return (
    <SidebarWrapper>
   <ToggleButton onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? '❌' : '☰'} {/* Display a cross when the sidebar is open and a hamburger icon when it's closed */}
      </ToggleButton>
    
    <SidebarContainer isopen={isOpen.toString()}>
      {/* Your Sidebar Content Here */}
      <SidebarMenu>
          <SidebarLink href="#users">Users</SidebarLink>
          <SidebarLink onClick={() => LinkClicked('/org')}>Organizations</SidebarLink>
          <SidebarLink onClick={() => LinkClicked('/app-type')}>App Types</SidebarLink>
          <SidebarLink onClick={() => LinkClicked('/app')}>Apps</SidebarLink>
          <SidebarLink onClick={() => LinkClicked('/app-data')}>App Data</SidebarLink>
        </SidebarMenu>
    </SidebarContainer>
  </SidebarWrapper>
  );
};

export default Sidebar;
