// sagas/orgSaga.js

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  orgPending,
  orgPaginatedList,
  orgList,
  orgCreate,
  orgFetch,
  orgUpdate
} from '../redux/actions/orgActions.js';

import {
  apiError
} from '../redux/actions/errorActions.js'


import {SG_GET_ORG, SG_GET_ORG_LIST, SG_CREATE_ORG, SG_FETCH_ORG, SG_UPDATE_ORG } from "../redux/actionTypes.js"

import { apiOrgPaginatedGet, apiOrgGet, apiOrgCreate, apiOrgFetch, apiOrgUpdate } from '../services/api.js';

function* handleGetPaginatedList(action) {
  yield put(orgPending());
  try {
    const response = yield call(apiOrgPaginatedGet);
    yield put(orgPaginatedList(response));

  } catch (error) {
    yield put(apiError(error.toString()));
  }
}

function* handleGetList(action) {
  yield put(orgPending());
  try {
    const response = yield call(apiOrgGet);
    yield put(orgList(response));

  } catch (error) {
    yield put(apiError(error.toString()));
  }
}


function* handleCreate(action) {
  yield put(orgPending());
    try {
      const response = yield call(apiOrgCreate, action.payload);
      yield put(orgCreate(response));
  
    } catch (error) {
      yield put(apiError(error.toString()));
    }
  }

  function* handleFetch(action) {
    yield put(orgPending());
    try {
      const response = yield call(apiOrgFetch, action.payload);
      yield put(orgFetch(response));
  
    } catch (error) {
      yield put(apiError(error.toString()));
    }
  }

  function* handleUpdate(action) {
    yield put(orgPending());
    try {
      const response = yield call(apiOrgUpdate, action.payload);
      yield put(orgUpdate(response));
  
    } catch (error) {
      yield put(apiError(error.toString()));
    }
  }

export default function* watchFunctions() {
  yield takeLatest(SG_GET_ORG, handleGetPaginatedList);
  yield takeLatest(SG_GET_ORG_LIST, handleGetList);
  yield takeLatest(SG_CREATE_ORG, handleCreate);
  yield takeLatest(SG_FETCH_ORG, handleFetch);
  yield takeLatest(SG_UPDATE_ORG, handleUpdate);
}
