// sagas/appSaga.js

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  appPending,
  appList,
  appCreate,
  appFetch,
  appUpdate
} from '../redux/actions/appActions.js';

import {
  apiError
} from '../redux/actions/errorActions.js'


import {SG_GET_APP, 
  SG_CREATE_APP, 
  SG_FETCH_APP, 
  SG_UPDATE_APP,
   } from "../redux/actionTypes.js"

import { apiAppPaginatedGet, apiAppCreate, apiAppFetch, apiAppUpdate } from '../services/api.js';

function* handleGetList(action) {
  yield put(appPending());
  try {
    const response = yield call(apiAppPaginatedGet);
    console.log(response)
    yield put(appList(response));

  } catch (error) {
    yield put(apiError(error.toString()));
  }
}

function* handleCreate(action) {
  yield put(appPending());
    try {
      const response = yield call(apiAppCreate, action.payload);
      yield put(appCreate(response));
  
    } catch (error) {
      yield put(apiError(error.toString()));
    }
  }

  function* handleFetch(action) {
    yield put(appPending());
    try {
      const response = yield call(apiAppFetch, action.payload);
      yield put(appFetch(response));
  
    } catch (error) {
      yield put(apiError(error.toString()));
    }
  }

  function* handleUpdate(action) {
    yield put(appPending());
    try {
      const response = yield call(apiAppUpdate, action.payload);
      yield put(appUpdate(response));
  
    } catch (error) {
      yield put(apiError(error.toString()));
    }
  }

export default function* watchFunctions() {
  yield takeLatest(SG_GET_APP, handleGetList);
  yield takeLatest(SG_CREATE_APP, handleCreate);
  yield takeLatest(SG_FETCH_APP, handleFetch);
  yield takeLatest(SG_UPDATE_APP, handleUpdate);
}
