// sagas/authSaga.js

import { call, put, takeLatest } from "redux-saga/effects";
import { loginSuccess, loginFailure } from "../redux/actions/authActions.js";
import { LOGIN_REQUEST, LOGOUT } from "../redux/actionTypes.js";

import { loginAPI } from "../services/api.js";

function* handleLogin(action) {
  try {
    const response = yield call(loginAPI, action.email, action.password);
    yield put(loginSuccess(response));

    const { token, expiry, email, refresh_token } = response;
    localStorage.setItem("token", token);
    localStorage.setItem("valid_upto", expiry);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("email", email);
  } catch (error) {
    yield put(loginFailure(error.toString()));
  }
}

function* handleLogout(action) {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("valid_upto");
    localStorage.removeItem("email");
  } catch (error) {
    yield put(loginFailure(error.toString()));
  }
}

export default function* watchLogin() {
  yield takeLatest(LOGIN_REQUEST, handleLogin);
  yield takeLatest(LOGOUT, handleLogout);
}
