// sagas/orgSaga.js

import { call, put, takeLatest } from "redux-saga/effects";

import {
  appDataPending,
  appDataList,
  appDataCreate,
  appDataFetch,
  appDataUpdate,
  appDataDelete,
} from "../redux/actions/appDataActions.js";

import { apiError } from "../redux/actions/errorActions.js";

import {
  SG_GET_APP_DATA,
  SG_CREATE_APP_DATA,
  SG_FETCH_APP_DATA,
  SG_UPDATE_APP_DATA,
  SG_DELETE_APP_DATA,
} from "../redux/actionTypes.js";

import {
  apiAppDataGet,
  apiAppDataGetList,
  apiAppDataCreate,
  apiAppDataFetch,
  apiAppDataUpdate,
  apiAppDataDelete,
} from "../services/api.js";

function* handleGetList(action) {
  yield put(appDataPending());
  try {
    const response = yield call(apiAppDataGetList, action.payload);
    yield put(appDataList(response));
  } catch (error) {
    yield put(apiError(error.toString()));
  }
}

function* handleCreate(action) {
  yield put(appDataPending());
  try {
    const response = yield call(apiAppDataCreate, action.payload);
    yield put(appDataCreate(response));
  } catch (error) {
    yield put(apiError(error.toString()));
  }
}

function* handleFetch(action) {
  yield put(appDataPending());
  try {
    const response = yield call(apiAppDataFetch, action.payload);
    yield put(appDataFetch(response));
  } catch (error) {
    yield put(apiError(error.toString()));
  }
}

function* handleUpdate(action) {
  yield put(appDataPending());
  try {
    const response = yield call(apiAppDataUpdate, action.payload);
    yield put(appDataUpdate(response));
  } catch (error) {
    yield put(apiError(error.toString()));
  }
}

function* handleDelete(action) {
  yield put(appDataPending());
  try {
    const response = yield call(apiAppDataDelete, action.payload);
    yield put(appDataDelete(response));
  } catch (error) {
    yield put(apiError(error.toString()));
  }
}

export default function* watchFunctions() {
  yield takeLatest(SG_GET_APP_DATA, handleGetList);
  yield takeLatest(SG_CREATE_APP_DATA, handleCreate);
  yield takeLatest(SG_FETCH_APP_DATA, handleFetch);
  yield takeLatest(SG_UPDATE_APP_DATA, handleUpdate);
  yield takeLatest(SG_DELETE_APP_DATA, handleDelete);
}
