import {
  PENDING_APP_DATA,
  PAGE_NUM_APP_DATA,
  GET_APP_DATA,
  CREATE_APP_DATA,
  FETCH_APP_DATA,
  UPDATE_APP_DATA,
  DELETE_APP_DATA,
} from "../actionTypes.js";

const initialState = {
  isLoading: false,
  app_data: [],
  pageNumber: 1,
  create: null,
  delete: null,
  fetch: null,
  update: null,
};

const orgReducer = (state = initialState, action) => {
  switch (action.type) {
    case PENDING_APP_DATA:
      return {
        ...state,
        isLoading: true,
        create: null,
        delete: null,
        fetch: null,
        update: null,
      };
    case PAGE_NUM_APP_DATA:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case GET_APP_DATA:
      return {
        ...state,
        app_data: action.payload,
        isLoading: false,
      };
    case CREATE_APP_DATA:
      return {
        ...state,
        isLoading: false,
        created: action.payload,
      };
    case FETCH_APP_DATA:
      return {
        ...state,
        isLoading: false,
        fetch: action.payload,
      };
    case UPDATE_APP_DATA:
      return {
        ...state,
        isLoading: false,
        update: action.payload,
      };
    case DELETE_APP_DATA:
      return {
        ...state,
        isLoading: false,
        deleted: action.payload,
      };
    default:
      return state;
  }
};

export default orgReducer;
