// actionTypes.js

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';
//
//
export const API_ERROR = 'API_ERROR'
//
export const CLEAR_ALL = 'CLEAR_ALL'
//
//
export const PENDING_ORG = 'PENDING_ORG';
export const PAGE_NUM_ORG = 'PAGE_NUM_ORG';
export const CLEAR_ORG = 'CLEAR_ORG';
export const GET_ORG = 'GET_ORG';
export const SG_GET_ORG = 'SG_GET_ORG';
export const GET_ORG_LIST = 'GET_ORG_LIST';
export const SG_GET_ORG_LIST = 'SG_GET_ORG_LIST';
export const CREATE_ORG = 'CREATE_ORG';
export const SG_CREATE_ORG = 'SG_CREATE_ORG';
export const FETCH_ORG = 'FETCH_ORG';
export const SG_FETCH_ORG = 'SG_FETCH_ORG';
export const UPDATE_ORG = 'UPDATE_ORG';
export const SG_UPDATE_ORG = 'SG_UPDATE_ORG';
export const DELETE_ORG = 'DELETE_ORG';
export const SG_DELETE_ORG = 'SG_DELETE_ORG';
//
//
export const PENDING_APP_TYPE = 'PENDING_APP_TYPE';
export const PAGE_NUM_APP_TYPE = 'PAGE_NUM_APP_TYPE';
export const CLEAR_APP_TYPE = 'CLEAR_APP_TYPE';
export const GET_APP_TYPE = 'GET_APP_TYPE';
export const SG_GET_APP_TYPE = 'SG_GET_APP_TYPE';
export const CREATE_APP_TYPE = 'CREATE_APP_TYPE';
export const SG_CREATE_APP_TYPE = 'SG_CREATE_APP_TYPE';
export const FETCH_APP_TYPE = 'FETCH_APP_TYPE';
export const SG_FETCH_APP_TYPE = 'SG_FETCH_APP_TYPE';
export const UPDATE_APP_TYPE = 'UPDATE_APP_TYPE';
export const SG_UPDATE_APP_TYPE = 'SG_UPDATE_APP_TYPE';
export const DELETE_APP_TYPE = 'DELETE_APP_TYPE';
export const SG_DELETE_APP_TYPE = 'SG_DELETE_APP_TYPE';
//
//
export const PENDING_APP = 'PENDING_APP';
export const PAGE_NUM_APP = 'PAGE_NUM_APP';
export const CLEAR_APP = 'CLEAR_APP';
export const GET_APP = 'GET_APP';
export const SG_GET_APP = 'SG_GET_APP';
export const CREATE_APP = 'CREATE_APP';
export const SG_CREATE_APP = 'SG_CREATE_APP';
export const FETCH_APP = 'FETCH_APP';
export const SG_FETCH_APP = 'SG_FETCH_APP';
export const UPDATE_APP = 'UPDATE_APP';
export const SG_UPDATE_APP = 'SG_UPDATE_APP';
export const DELETE_APP = 'DELETE_APP';
export const SG_DELETE_APP = 'SG_DELETE_APP';
//
//
export const PENDING_APP_DATA = 'PENDING_APP_DATA';
export const PAGE_NUM_APP_DATA = 'PAGE_NUM_APP_DATA';
export const CLEAR_APP_DATA = 'CLEAR_APP_DATA';
export const GET_APP_DATA = 'GET_APP_DATA';
export const SG_GET_APP_DATA = 'SG_GET_APP_DATA';
export const CREATE_APP_DATA = 'CREATE_APP_DATA';
export const SG_CREATE_APP_DATA = 'SG_CREATE_APP_DATA';
export const FETCH_APP_DATA = 'FETCH_APP_DATA';
export const SG_FETCH_APP_DATA = 'SG_FETCH_APP_DATA';
export const UPDATE_APP_DATA = 'UPDATE_APP_DATA';
export const SG_UPDATE_APP_DATA = 'SG_UPDATE_APP_DATA';
export const DELETE_APP_DATA = 'DELETE_APP_DATA';
export const SG_DELETE_APP_DATA = 'SG_DELETE_APP_DATA';
//
//
