// redux/actions/authActions.js
import {
  PENDING_APP_DATA,
  GET_APP_DATA,
  CREATE_APP_DATA,
  FETCH_APP_DATA,
  UPDATE_APP_DATA,
  DELETE_APP_DATA,
  } from '../actionTypes.js';

export const appDataPending = () => ({
    type: PENDING_APP_DATA,
  });

export const appDataList = (payload) => ({
  type: GET_APP_DATA,
  payload
});

export const appDataCreate = (payload) => ({
  type: CREATE_APP_DATA,
  payload
});

export const appDataFetch = (payload) => ({
  type: FETCH_APP_DATA,
  payload
});

export const appDataUpdate = (payload) => ({
  type: UPDATE_APP_DATA,
  payload
});

export const appDataDelete = (payload) => ({
  type: DELETE_APP_DATA,
  payload
});
