import React, { useState, useEffect } from "react";
import styled from "styled-components";

import SearchBar from "./SearchBar";
import RowCard from "./RowCard";

import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { SG_GET_ORG, PAGE_NUM_ORG } from "../../redux/actionTypes";

import CustomPagination from './CustomPagination';

const List = ({ reload }) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    dispatch({
      type: SG_GET_ORG,
      payload: `page=${page}${searchTerm ? `&search=${searchTerm}` : ""}`,
    });

    setLoading(true);
  }, [dispatch, page, searchTerm, reload]);

  const { get_org } = useSelector(
    (state) => ({
      get_org: state.org,
    }),
    shallowEqual
  );

  useEffect(() => {
    setResults(get_org?.org?.organizations);
    setPage(get_org?.pageNumber);
    setTotalPages(Math.ceil(get_org?.org?.totalRecords / get_org?.org?.pageSize));
  }, [get_org, page]);

  function handlePageChange(selectedPage) {
    setPage(selectedPage.selected + 1);
    dispatch({
      type: PAGE_NUM_ORG,
      payload: selectedPage.selected + 1,
    });
  }

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLoading(get_org?.isLoading);
    }, 2000);
    return () => clearTimeout(timer1);
  }, [get_org]);

  
  return (
    <Container>
      <LoaderContainer>
        {loading && (
          <CustomLoader>
            {/* Your loading spinner or component */}
            Loading...
          </CustomLoader>
        )}
        <RowCardContainer>
          {results && <RowCard rows={results} />}
        </RowCardContainer>
      </LoaderContainer>


       <CustomPagination
        pageCount={totalPages || 1} // Replace with the total number of pages
        onPageChange={handlePageChange}
        initialPage={page}
      />

    </Container>
  );
};

export default List;

const RowCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const Container = styled.div`
  padding: 30px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const LoaderContainer = styled.div`
  position: relative;
  min-height: 300px;
`;

const CustomLoader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* You can customize the color of your loading text */
  z-index: 1000; /* Make sure it's above other elements */

  /* Additional styling for your loading spinner or component */
`;

