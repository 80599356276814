import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import styled from "styled-components";

import { SG_FETCH_ORG } from "../../redux/actionTypes";

const ViewDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [detailObj, setDetailObj] = useState("");

  const { get_org } = useSelector(
    (state) => ({
      get_org: state.org,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (id) {
      dispatch({
        type: SG_FETCH_ORG,
        payload: { id },
      });
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (get_org) {
      setDetailObj(get_org?.fetch);
    }
  }, [get_org]);

  function GetNew() {
    navigate(`/org/edit/${id}`);
  }

  return (
    <FormContainer>
      <ButtonContainer>
        <EditButton disabled={get_org?.isLoading} onClick={GetNew}>
          Update
        </EditButton>
      </ButtonContainer>
      <RowGroup>
        <Label>Id:</Label>
        <Label>{detailObj?.id}</Label>
      </RowGroup>
      <RowGroup>
        <Label>Name:</Label>
        <Label>{detailObj?.name}</Label>
      </RowGroup>

      <RowGroup>
        <Label>Created:</Label>
        <Label>{detailObj?.created_at}</Label>
      </RowGroup>

      <RowGroup>
        <Label>Updated:</Label>
        <Label>{detailObj?.updated_at}</Label>
      </RowGroup>
    </FormContainer>
  );
};

export default ViewDetails;

const FormContainer = styled.div`
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  align-self: flex-end;
  margin-bottom: 15px;
`;

const EditButton = styled.button`
  background-color: #6a5acd; /* This is a shade of violet */
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #483d8b; /* This is a darker shade for hover effect */
  }
`;

const RowGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-size: 16px;
  margin: 8px;
`;

// Rest of your components...
