// redux/actions/authActions.js
import {
  PENDING_APP_TYPE,
  GET_APP_TYPE,
  CREATE_APP_TYPE,
  FETCH_APP_TYPE,
  UPDATE_APP_TYPE,
  DELETE_APP_TYPE,
  } from '../actionTypes.js';

export const appTypePending = () => ({
    type: PENDING_APP_TYPE,
  });

export const appTypeList = (payload) => ({
  type: GET_APP_TYPE,
  payload
});

export const appTypeCreate = (payload) => ({
  type: CREATE_APP_TYPE,
  payload
});

export const appTypeFetch = (payload) => ({
  type: FETCH_APP_TYPE,
  payload
});

export const appTypeUpdate = (payload) => ({
  type: UPDATE_APP_TYPE,
  payload
});

export const appTypeDelete = (payload) => ({
  type: DELETE_APP_TYPE,
  payload
});
