// components/Login/styles.js
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f7f7f7;
`;

export const FormHeader = styled.div`
  padding: 1rem 1.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 1rem;


`;

export const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background: #fff;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
`;

export const Input = styled.input`
    padding: 0.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
`;

export const Button = styled.button`
    padding: 0.5rem 1rem;
    background-color: #0077ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: #0055cc;
    }
`;
