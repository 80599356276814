import styled from 'styled-components';
const COLORS = {
    primary: '#2C3E50',
    secondary: '#ECF0F1',
    // ... add other colors as required
  };
  
// export const TopBarContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 15px 30px;
//   background-color: ${COLORS.primary};
// `;

export const TopBarContainer = styled.div`
    display: flex;
    justify-content: space-between; /* This will push child elements to both ends of the container */
    align-items: center;
    background-color: #2C3E50;
    padding: 15px 30px;
    /* Add any other styles for TopBarContainer */
`;

export const LinkButton = styled.button`
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 8px 12px;
    cursor: pointer;
    /* Additional styling for the button */
`;


export const UserAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

// Assuming your Sidebar has a fixed width
const sidebarWidth = '250px';

export const SidebarWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`;

export const ToggleButton = styled.button`
  */
  background-color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 0 5px 5px 0; /* Rounded corners on the right side */

  position: absolute;
  z-index: 1000; /* Ensure the button is above other elements */
  left: 0; /* Place the button on the left side of the screen */
  top: 6%; /* Adjust as needed to position vertically 

  // Move button with sidebar
  transition: transform 0.3s ease-in-out;
`;

export const SidebarContainer = styled.div`
  margin-top: 70px;
  position: fixed;
  top: 0;
  left: 0; 
  width: 300px;
  height: 100vh;
  z-index: 900; 
  background-color: #f5f5f5;
  transform: ${props => (props.isopen === "true" ? 'translateX(0)' : 'translateX(-100%)')}; 
  transition: 0.3s;  // Animation effect when toggling the sidebar
  overflow: hidden;
`;


export const SidebarButton = styled.button`
  position: absolute;
  top: 20px;
  right: -50px;
  width: 50px;
  height: 50px;
  background-color: #444;
  color: #fff;
  border: none;
  cursor: pointer;
  z-index: 1000; 
`;

// Sidebar styled component
export const Sidebar = styled.div`

  width: 250px;
  background-color: #343a40;
  min-height: 100vh;
  float: left;
  color: #ffffff;
`;

// Topbar styled component
export const Topbar = styled.div`
  background-color: #007bff;
  height: 60px;
  line-height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
`;

// Main Content styled component
export const MainContent = styled.div`
  min-height: 100vh;

`;

// Authenticated Layout Wrapper
export const AuthenticatedLayoutWrapper = styled.div`
  font-family: 'Arial', sans-serif;
`;

export const BarTitle = styled.p`
    font-size: .8em;
    margin: 10px 0;
    color: #ffffff;
`;


export const SidebarMenu = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-top: 45px;
`;

export const SidebarLink = styled.a`
  padding: 15px;
  border-bottom: 1px solid #ddd;
  text-decoration: none;
  color: #333;
  display: block;

  &:hover {
    color: #0288d1;
  }
`;