// redux/reducers/authReducer.js

import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT
  } from '../actionTypes.js';
  
  const getTokenFromLocalStorage = () => {
    const token = localStorage.getItem("token");
    return token ? token : null;
};

const getRefreshTokenFromLocalStorage = () => {
  const token = localStorage.getItem("refresh_token");
  return token ? token : null;
};
const getValidUptoFromLocalStorage = () => {
    const expiry = localStorage.getItem("valid_upto");
    return expiry ? expiry : null;
};

const getUsernameFromLocalStorage = () => {
    const email = localStorage.getItem("email");
    return email ? email : null;
};

  const initialState = {
    
    isLoading: false,
    userData: {
        token: getTokenFromLocalStorage(),
        expiry: getValidUptoFromLocalStorage(),
        email: getUsernameFromLocalStorage(),
        refresh_token: getRefreshTokenFromLocalStorage()
    },
    error: null
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOGIN_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case LOGIN_SUCCESS:
        return {
          ...state,
          isLoading: false,
          userData: action.payload
        };
      case LOGIN_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.error
        };
     case LOGOUT:
        return {
            ...state,
            isLoading: false,
            error: action.error
          };
      default:
        return state;
    }
  };
  
  export default authReducer;
  

