// sagas/orgSaga.js

import { call, put, takeLatest } from 'redux-saga/effects';
import {
  appTypePending,
  appTypeList,
  appTypeCreate,
  appTypeFetch,
  appTypeUpdate
} from '../redux/actions/appTypeActions.js';

import {
  apiError
} from '../redux/actions/errorActions.js'


import {SG_GET_APP_TYPE, SG_CREATE_APP_TYPE, SG_FETCH_APP_TYPE, SG_UPDATE_APP_TYPE } from "../redux/actionTypes.js"

import { apiAppTypeGet, apiAppTypeCreate, apiAppTypeFetch, apiAppTypeUpdate } from '../services/api.js';

function* handleGetList(action) {
  yield put(appTypePending());
  try {
    const response = yield call(apiAppTypeGet);
    yield put(appTypeList(response));

  } catch (error) {
    yield put(apiError(error.toString()));
  }
}

function* handleCreate(action) {
  yield put(appTypePending());
    try {
      const response = yield call(apiAppTypeCreate, action.payload);
      yield put(appTypeCreate(response));
  
    } catch (error) {
      yield put(apiError(error.toString()));
    }
  }

  function* handleFetch(action) {
    yield put(appTypePending());
    try {
      const response = yield call(apiAppTypeFetch, action.payload);
      yield put(appTypeFetch(response));
  
    } catch (error) {
      yield put(apiError(error.toString()));
    }
  }

  function* handleUpdate(action) {
    yield put(appTypePending());
    try {
      const response = yield call(apiAppTypeUpdate, action.payload);
      yield put(appTypeUpdate(response));
  
    } catch (error) {
      yield put(apiError(error.toString()));
    }
  }

export default function* watchFunctions() {
  yield takeLatest(SG_GET_APP_TYPE, handleGetList);
  yield takeLatest(SG_CREATE_APP_TYPE, handleCreate);
  yield takeLatest(SG_FETCH_APP_TYPE, handleFetch);
  yield takeLatest(SG_UPDATE_APP_TYPE, handleUpdate);
}
