// components/OrgForm.js

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";

import styled from "styled-components";

import {
  SG_CREATE_ORG,
  SG_FETCH_ORG,
  SG_UPDATE_ORG,
} from "../../redux/actionTypes";

const OrgForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [name, setName] = useState("");

  const { get_org } = useSelector(
    (state) => ({
      get_org: state.org,
    }),
    shallowEqual
  );


  useEffect(() => {
    if (id) {
      dispatch({ 
        type: SG_FETCH_ORG, 
        payload: {id} });
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (get_org?.fetch?.name) {
      setName(get_org?.fetch?.name);
    }
  }, [get_org]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const orgData = { name };

    if (id) {
      dispatch({ type: SG_UPDATE_ORG, payload: { id: id, data: orgData } });
    } else {
      dispatch({ type: SG_CREATE_ORG, payload: orgData });
    }

    // onClose();
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Name:</Label>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <Button type="submit">{id ? "Edit Org" : "Add Org"}</Button>
      </form>
    </FormContainer>
  );
};

export default OrgForm;

const FormContainer = styled.div`
  max-width: 600px;
  margin:  auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
`;

const FormGroup = styled.div`
  margin: 0 15px 10px 5px;
`;

const Label = styled.label`
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 16px;
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;
