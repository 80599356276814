// components/OrgForm.js

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";


import styled from "styled-components";

import {
  SG_CREATE_APP_DATA,
  //   SG_FETCH_APP_DATA,
  SG_UPDATE_APP_DATA,
} from "../../redux/actionTypes";

const FormData = ({ applicationId }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [name, setName] = useState("");
  const [file, setFile] = useState(null); // State to hold the selected file


  const { get_app_data } = useSelector(
    (state) => ({
      get_app_data: state.app_data,
    }),
    shallowEqual
  );

  const handleFileChange = (files) => {
    console.log(files)
    // Set the selected file in state
    setFile(files[0]);
  };

  console.log(file)

  //   useEffect(() => {
  //     if (id) {
  //       dispatch({
  //         type: SG_FETCH_APP_TYPE,
  //         payload: {id} });
  //     }
  //   }, [dispatch, id]);

//   useEffect(() => {
//     if (get_app_data?.fetch?.name) {
//       setName(get_app_data?.fetch?.name);
//     }
//   }, [get_app_data]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const payloadData = { name, application_id: applicationId, csv_file: file };
    // const formData = new FormData();
    // formData.append("name", name);
    // formData.append("application_id", applicationId);
    // formData.append("csvfile", file); // Append the file to the FormData

    // if (id) {
    //   dispatch({
    //     type: SG_UPDATE_APP_DATA,
    //     payload: { id: id, data: payloadData },
    //   });
    // } else {
      dispatch({ type: SG_CREATE_APP_DATA, payload: payloadData });
    // }

    // onClose();
  };

  return (
    <FormContainer>
      <Title>Application Data</Title>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Name:</Label>
          <Input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label>File:</Label>
          <Input
            type="file"
            onChange={(e) => handleFileChange(e.target.files)}
          />
        </FormGroup>
        {/* <FormGroup>
      <Label>Additional Field:</Label>
      <Input
        type="text"
        value={additionalField}
        onChange={(e) => setAdditionalField(e.target.value)}
      />
    </FormGroup> */}
        <Button type="submit">{id ? "Update" : "Add"}</Button>
      </form>
    </FormContainer>
  );
};

export default FormData;

const FormContainer = styled.div`
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
`;

const FormGroup = styled.div`
  margin: 0 15px 10px 5px;
`;

const Title = styled.label`
  display: block;
  font-size: 20px;
  margin-bottom: 8px;
`;

const Label = styled.label`
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 16px;
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #45a049;
  }
`;
