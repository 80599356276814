import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import List from "./List.js";
import Details from "./Detail.js";
import Create from "./Create.js";

function AppTypeIndex() {
  const navigate = useNavigate();
  const location = useLocation();
  const [newResponse, setNewResponse] = useState(0);
  const [loadData, setLoadData] = useState(false);

  function BackArrow() {
    navigate("/app-type");
  }

  function GetNew() {
    setNewResponse(Math.random());
    navigate("/app-type/create/");
  }

  const { get_app_type } = useSelector(
    (state) => ({
      get_app_type: state.app_type,
    }),
    shallowEqual
  );

  useEffect(() => {
    setLoadData(true);
    const timer1 = setTimeout(() => {
      setLoadData(get_app_type?.isLoading);
    }, 500);
    return () => clearTimeout(timer1);
  }, [get_app_type]);

  return (
    <>
      <HeaderContainer>
        <HeaderText as="h2" onClick={BackArrow}>
          Application Type
        </HeaderText>
        <AddNewButton disabled={loadData} onClick={GetNew}>
          Create New
        </AddNewButton>
      </HeaderContainer>

      <Routes>
        <Route path="/" element={<List />} />
        <Route
          path="details/:id"
          element={
            <Details
              newResponse={newResponse}
              setNewResponse={setNewResponse}
            />
          }
        />
        <Route
          path="create"
          element={
            <Create newResponse={newResponse} setNewResponse={setNewResponse} />
          }
        />
        <Route
          path="edit/:id"
          element={
            <Create newResponse={newResponse} setNewResponse={setNewResponse} />
          }
        />
      </Routes>
    </>
  );
}

export default AppTypeIndex;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
`;

const HeaderText = styled.div`
  margin-top: 40px;
  cursor: pointer;
`;

const AddNewButton = styled.button`
  display: ${({ hide }) => (hide ? "none" : "block")};
  background-color: #e85d00; /* Darker shade of orange */
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #d35400; /* Even darker shade for hover effect */
  }
`;

