// store.js or index.js
import createSagaMiddleware from 'redux-saga';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './redux/rootReducer';  // your combined reducers
import rootSaga from './sagas/rootSaga';


const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware]  // add saga middleware to the middleware stack
});

sagaMiddleware.run(rootSaga);  // Start your root saga

export default store;
