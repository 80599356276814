import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';



const Hero = () => {
    const navigate = useNavigate();
    return (
        <>
        <HeroContainer>
            <Title>List of our Webapps</Title>
            
        </HeroContainer>    
            <BoxContainer>
                <Box>
                    <BoxHeader>Organization</BoxHeader>
                    <BoxContent>
                        {/* You can add any content-specific information here */}
                    </BoxContent>
                    <BoxFooter>
                        <LearnMoreButton onClick={() => navigate('/org')}>More</LearnMoreButton>
                    </BoxFooter>
                </Box>
                <Box>
                    <BoxHeader>Application Type</BoxHeader>
                    <BoxContent>
                        {/* You can add any content-specific information here */}
                    </BoxContent>
                    <BoxFooter>
                        <LearnMoreButton onClick={() => navigate('/app-type')}>More</LearnMoreButton>
                    </BoxFooter>
                </Box>
                <Box>
                    <BoxHeader>Application Data</BoxHeader>
                    <BoxContent>
                        {/* You can add any content-specific information here */}
                    </BoxContent>
                    <BoxFooter>
                        <LearnMoreButton onClick={() => navigate('/app-data')}>More</LearnMoreButton>
                    </BoxFooter>
                </Box>
      </BoxContainer>
      <ExplainContainer>
            <SubTitle>More information about our application</SubTitle>
            
        </ExplainContainer>
        </>
    );
};

export default Hero;

const HeroContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20vh;
    background-color: #f2f2f2;
    padding: 20px;
`;

const ExplainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30vh;
    background-color: #f2f2f2;
    padding: 10px;
`;

const Title = styled.h1`
    font-size: 2em;
    margin: 20px 0;
`;

const SubTitle = styled.h3`
    font-size: 1.5em;
    top-margin: 100px;
    // margin: 100px 0;
`;

const BoxContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 10px;
`;

const LearnMoreButton = styled.button`
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`;

const Box = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 20px;
    background-color: #e7e7e7;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    min-height: 30vh;
`;

const BoxHeader = styled.div`
    font-weight: bold;
    border-bottom: 1px solid #d4d4d4;
    padding-bottom: 10px;
    margin-bottom: 10px;
`;

const BoxContent = styled.div`
    flex-grow: 1;
`;

const BoxFooter = styled.div`
    display: flex;
    justify-content: center;
    margin-top: auto; // This will push it to the bottom
`;

