// redux/reducers/authReducer.js

import {
  PENDING_ORG,
  PAGE_NUM_ORG,
  GET_ORG,
  GET_ORG_LIST,
  CREATE_ORG,
  FETCH_ORG,
  UPDATE_ORG,
  DELETE_ORG,
  CLEAR_ORG,
  CLEAR_ALL,
} from "../actionTypes.js";

const initialState = {
  isLoading: false,
  org: [],
  pageNumber: 1,
  create: null,
  delete: null,
  fetch: null,
  update: null,
};

const orgReducer = (state = initialState, action) => {
  switch (action.type) {
    case PENDING_ORG:
      return {
        ...state,
        isLoading: true,
        create: null,
        delete: null,
        fetch: null,
        update: null,
      };
    case PAGE_NUM_ORG:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case GET_ORG:
      return {
        ...state,
        org: action.payload,
        isLoading: false,
        created: false,
      };
    case GET_ORG_LIST:
        return {
          ...state,
          list: action.payload,
          isLoading: false,
          created: false,
        };
    case CREATE_ORG:
      return {
        ...state,
        isLoading: false,
        created: action.payload,
      };
    case FETCH_ORG:
      return {
        ...state,
        isLoading: false,
        fetch: action.payload,
      };
    case UPDATE_ORG:
      return {
        ...state,
        isLoading: false,
        update: action.payload,
      };
    case DELETE_ORG:
      return {
        ...state,
        isLoading: false,
        created: false,
        deleted: action.payload,
      };
    case CLEAR_ALL:
    case CLEAR_ORG:
        return {
          ...state,
          ...initialState,
        }
    default:
      return state;
  }
};

export default orgReducer;
