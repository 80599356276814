import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes";

function MainContent() {
  return <AppRoutes />;
}

function App() {
  return (
    <Router>
      <MainContent />
    </Router>
  );
}

export default App;
