// components/layout/home.js
import {  MainContent, AuthenticatedLayoutWrapper } from './styles.js'; // Assuming you saved the styled components in a separate file
import Sidebar  from './sidebar.js';
import Topbar from "./topbar.js"
import React from 'react';

const Home = ({ children }) => {
    return (
        <AuthenticatedLayoutWrapper>
          <Sidebar />
    
          <Topbar />
    
          <MainContent>
            
            {children}
          </MainContent>
        </AuthenticatedLayoutWrapper>
      );
}


export default Home;
