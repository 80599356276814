import { combineReducers } from 'redux';
import authReducer from './reducers/authReducer';
import orgReducer from './reducers/orgReducers';
import appTypeReducer from "./reducers/appTypeReducers";
import appReducer from "./reducers/appReducers";
import appDataReducer from "./reducers/appDataReducers";

const rootReducer = combineReducers({
    auth: authReducer,
    org: orgReducer,
    app_type: appTypeReducer,
    app: appReducer,
    app_data: appDataReducer,
});

export default rootReducer;
