import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { TopBarContainer, LinkButton, BarTitle } from "./styles.js";
import { LOGOUT } from "../../redux/actionTypes";

const TopBar = ({ userName, userRole }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth, shallowEqual);

  function Signout() {
    dispatch({
      type: LOGOUT,
    });
    navigate("/");
  }

  return (
    <TopBarContainer>
      {/* Other elements such as icons and menus */}

      <div>
        <BarTitle>Logged in as {auth?.userData?.email}</BarTitle>
      </div>
      <div>
        <LinkButton
          onClick={() => {
            Signout();
          }}
        >
          Logout
        </LinkButton>
      </div>
    </TopBarContainer>
  );
};

export default TopBar;
