// sagas/rootSaga.js
import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import orgSaga from "./orgSaga";
import appTypeSaga from "./appTypeSaga"
import appSaga from "./appSaga"
import appDataSaga from "./appDataSaga"

export default function* rootSaga() {
  yield all([
    authSaga(),
    orgSaga(),
    appTypeSaga(),
    appSaga(),
    appDataSaga(),
    // Add other sagas here as your app grows
  ]);
}
